/**
 * Single DataLayer - Secondary part
 * Placed in the footer, pushes data to sdl object
 * Primary part (singleDL-primary.js) is placed in the <head>
 * @version 1.1.0
 * @author Vendula Frýzlová <vendula.fryzlova@avast.com>, Jaroslav Slaby <jaroslav.slaby@avast.com>, Fedor Kušš <fedor.kuss@avast.com>
 * @copyright Avast Software s.r.o &copy;2022
 */

(function sdlSecondary() {
	'use strict';

    // Function to safely retrieve values from nested objects
    function getSafe(obj, path) {
        var parts = path.split('.');
        var acc = obj;
        for (var i = 0; i < parts.length; i++) {
            if (acc && typeof acc[parts[i]] !== 'undefined') {
                acc = acc[parts[i]];
            } else {
                return 'unknown - ' + parts[i];
            }
        }
        return acc;
    }

    // Function to remove unwanted parameters from URL
    function removeUnwantedParamsFromUrl(urlParams) {
        var unwantedParams = ['ASWPARAM', 'aswparam', '_ga', '_gl'];
        for (var i = 0; i < unwantedParams.length; i++) {
            urlParams.delete(unwantedParams[i]);
        }
        var URLWithoutUnwantedParams = window.location.host + window.location.pathname + '?' + urlParams.toString();
        return URLWithoutUnwantedParams;
    }

	window.sdl = window.sdl || [];

	try {
		var sdlSecondaryObj = {
			// After click on a button, create object and push to sdl
			buttons: function () {
				document.addEventListener('click', function (e) {
					var button,
						form,
						popup;

					// DL buttons
					if (button = e.target.closest('a[data-role="download-link"]')) {
						sdl.push({
							user: {
								download: {
									products: [{
										campaign: sdlSecondaryObj.checkAttribute(button, 'data-campaign'),
										campaignMarker: sdlSecondaryObj.checkAttribute(button, 'data-campaign-marker'),
										sku: sdlSecondaryObj.checkAttribute(button, 'data-download-name'),
										maintenance: 0,
										seats: 1,
										quantity: 1,
										currencyCode: 'USD',
										category: 'Consumer',
										price: 0,
										tax: 0,
										brand: 'Avast',
										link: sdlSecondaryObj.removeUnwantedParamsFromLink(button.href),
										offerType: 'download',
										localPrice: 0,
										localTax: 0
									}]
								}
							},
							event: 'user.download.products'
						});

                        // Download popup
                        if (popup = document.querySelector('[data-behavior="downloadPopup"]')) {
                            if (popup) {  // Ensure popup exists
                                sdl.push({
                                    system: {
                                        modal: {
                                            id: popup.id,
                                            component: sdlSecondaryObj.checkAttribute(popup, 'data-cmp-name'),
                                            path: '/' + getSafe(sdlObj, 'screen.locale') + '/' + sdlSecondaryObj.checkAttribute(popup, 'data-cmp-name')
                                        }
                                    },
                                    event: 'system.modal'
                                });

                                var URLParams = new URLSearchParams(window.location.search);
                                sdl.push({
                                    event: 'screen',
                                    screen: {
                                        location: {
                                            url: window.location.search ? removeUnwantedParamsFromUrl(URLParams) : window.location.host + window.location.pathname,
                                            woParams: window.location.host + window.location.pathname,
                                            protocol: window.location.protocol.replace(':', ''),
                                            path: '/' + getSafe(sdlObj, 'screen.locale') + '/' + getSafe(sdlObj, 'screen.path'),
                                            hostname: getSafe(sdlObj, 'screen.hostname'),
                                            hash: window.location.hash,
                                            params: window.location.search ? Object.fromEntries(URLParams) : ''
                                        },
                                        locale: getSafe(sdlObj, 'screen.locale'),
                                        screenCategory: getSafe(sdlObj, 'screen.screenCategory'),
                                        title: document.title,
                                        type: 'modal',
                                        name: getSafe(sdlObj, 'screen.name'),
                                        section: popup.id,
                                        lineOfBusiness: getSafe(sdlObj, 'screen.lineOfBusiness'),
                                        screenId: getSafe(sdlObj, 'screen.screenId')
                                    }
                                });
                            }
                        }
					}

					// Buy buttons
					if (button = e.target.closest('a[data-role="cart-link"]')) {
						var multipleProducts = button.getAttribute('data-product-id') && button.getAttribute('data-product-id').indexOf('|') > -1 ? true  : false;

						if (multipleProducts) {
							var productsArray = [],
								attributes = {},
								attributesList = ['data-product-id', 'data-quantity', 'data-seats', 'data-maintenance', 'data-campaign', 'data-campaign-marker', 'data-price', 'data-product-category', 'data-currency'],
								productsAmount = button.getAttribute('data-product-id') ? button.getAttribute('data-product-id').split('|').length : 0;
                            
							// creating the object of arrays
							// we trust that all data attributes have the respective number of values. If some attribute is missing then we add empty strings to the array 
							function missingAttributeFill(len) {
								return new Array(len).fill('');
							}

                            
							for (var j = 0; j < attributesList.length; j++) {
								attributes[attributesList[j]] = button.getAttribute(attributesList[j]) && button.getAttribute(attributesList[j]).indexOf('|') > -1
									? button.getAttribute(attributesList[j]).split('|') 
									: missingAttributeFill(productsAmount) 
							} 

							for (var i = 0; i < productsAmount; i++) {
								productsArray.push({
									campaign: attributes['data-campaign'][i],
									campaignMarker: attributes['data-campaign-marker'][i],
									sku: attributes['data-product-id'][i],
									maintenance: Number(attributes['data-maintenance'][i]),
									seats: Number(attributes['data-seats'][i]),
									quantity: Number(attributes['data-quantity'][i]),
									currencyCode: 'USD',
									category: attributes['data-product-category'][i],
									brand: 'Avast',
									link: sdlSecondaryObj.removeUnwantedParamsFromLink(button.href),
									offerType: 'buy',
									localCurrencyCode: attributes['data-currency'][i],
									localPrice: Number(attributes['data-price'][i])
								});
							}

							sdl.push({
								user: {
									buy: { products: productsArray }
								},
								event: 'user.buy.products'
							});
						} else {
							sdl.push({
								user: {
									buy: {
										products: [{
											campaign: sdlSecondaryObj.checkAttribute(button, 'data-campaign'),
											campaignMarker: sdlSecondaryObj.checkAttribute(button, 'data-campaign-marker'),
											sku: sdlSecondaryObj.checkAttribute(button, 'data-product-id'),
											maintenance: Number(sdlSecondaryObj.checkAttribute(button, 'data-maintenance')),
											seats: Number(sdlSecondaryObj.checkAttribute(button, 'data-seats')),
											quantity: Number(button.hasAttribute('data-quantity') ? button.getAttribute('data-quantity') : '1'),
											currencyCode: 'USD',
											category: sdlSecondaryObj.checkAttribute(button, 'data-product-category'),
											brand: 'Avast',
											link: sdlSecondaryObj.removeUnwantedParamsFromLink(button.href),
											offerType: 'buy',
											localCurrencyCode: sdlSecondaryObj.checkAttribute(button, 'data-currency'),
											localPrice: Number(button.hasAttribute('data-price') ? button.getAttribute('data-price') : '0')
										}]
									}
								},
								event: 'user.buy.products'
							});
						}
					}

					// Media buttons
					if (button = e.target.closest('a[href*=".pdf"], a[data-role="media"]')) {
						sdl.push({
							user: {
								download: {
									media: {
										type: 'media',
										name: button.href.match(/.*\/(.*?\..*)/) ? button.href.match(/.*\/(.*?\..*)/)[1].split('?')[0] : null,
										url: sdlSecondaryObj.removeUnwantedParamsFromLink(window.location.href)
									}
								}
							},
							event: 'user.download.media'
						});
					}

					// Function buttons - Close
					if (button = e.target.closest('button.modal-close')) {
						var modal = button.closest('.modal');
						if (modal) {
							sdl.push({
								user: {
									click: {
										element: {
											actionType: 'close',
											component: 'cmp-modal',
											path: '/' + getSafe(sdlObj, 'screen.locale') + '/cmp-modal',
											id: modal.id,
										}
									}
								},
								event: 'user.click.element'
							});
							sdl.push({
								screen: {		
									type: getSafe(sdlObj, 'screen.type'),
									section: null						
								}
							})
						}
					}

					// Function buttons - Close DTYP modal
					if (button = e.target.closest('.js-dtp-close')) {
						var modal = button.closest('.js-popup-dtp');
						if (modal) {
							sdl.push({
								user: {
									click: {
										element: {
											actionType: 'close',
											component: 'dtyp-popup',
											path: '/' + getSafe(sdlObj, 'screen.locale') + '/dtyp-popup',
											id: modal.id,
										}
									}
								},
								event: 'user.click.element'
							});
							sdl.push({
								screen: {		
									type: getSafe(sdlObj, 'screen.type'),
									section: null						
								}
							})							
						}
					}

					// Other link buttons
					if (button = e.target.closest('a[data-role="promo-link"], a[data-role="cta-link"]')) {
						sdl.push({
							user: {
								click: {
									link: {
										clickUrl: sdlSecondaryObj.checkAttribute(button, 'href'),
										url: sdlSecondaryObj.removeUnwantedParamsFromLink(window.location.href),
										type: sdlSecondaryObj.checkAttribute(button, 'data-role'),
										text: e.target.closest('span') ? e.target.closest('span').textContent : ''
									}
								}
							},
							event: 'user.click.link'
						});
					}

					// Submit form buttons
					if (form = e.target.closest('form')) {
						sdl.push({
							user: {
								send: {
									form: {
										id: sdlSecondaryObj.checkAttribute(form, 'id'),
										name: sdlSecondaryObj.checkAttribute(form, 'action'),
										url: sdlSecondaryObj.removeUnwantedParamsFromLink(window.location.href)
									}
								}
							},
							event: 'user.send.form'
						});
					}
				});
			},

			// Modal
			modal: function () {
				var modals = document.querySelectorAll('.modal');
				for (var i = 0; i < modals.length; i++) {
					var modal = modals[i];

					modal.addEventListener('shown.bs.modal', function (e) {
						modal = e.target;
						sdl.push({
							system: {
								modal: {
									id: modal.id,
									component: sdlSecondaryObj.checkAttribute(modal, 'data-cmp-name'),
									path: '/' + getSafe(sdlObj, 'screen.locale') + '/' + sdlSecondaryObj.checkAttribute(modal, 'data-cmp-name')
								}
							},
							event: 'system.modal'
						});

						var URLParams = new URLSearchParams(window.location.search);
						sdl.push({
							event: 'screen',
							screen: {
								location: {
									url: window.location.search ? removeUnwantedParamsFromUrl(URLParams) : window.location.host + window.location.pathname,
									woParams: window.location.host + window.location.pathname,
									protocol: window.location.protocol.replace(':', ''),
									path: '/' + getSafe(sdlObj, 'screen.locale') + '/' + getSafe(sdlObj, 'screen.path'),
									hostname: getSafe(sdlObj, 'screen.hostname'),
									hash: window.location.hash,
									params: window.location.search ? Object.fromEntries(URLParams) : ''
								},
								locale: getSafe(sdlObj, 'screen.locale'),
								screenCategory: getSafe(sdlObj, 'screen.screenCategory'),
								title: document.title,
								type: 'modal',
								name: getSafe(sdlObj, 'screen.name'),
								section: modal.id,
								lineOfBusiness: getSafe(sdlObj, 'screen.lineOfBusiness'),
								screenId: getSafe(sdlObj, 'screen.screenId')
							}
						});
					});
				}
			},

			// Check if element has specific data attribute and get its value
			checkAttribute: function (clickedElement, attributeName) {
				return clickedElement.hasAttribute(attributeName) ? clickedElement.getAttribute(attributeName) : '';
			},

			// Remove unwanted URL parameters from a link
			removeUnwantedParamsFromLink: function (href) {
				var hash = window.location.hash,
					url = new URL(href),
					URLParams = new URLSearchParams(url.search),
					unwantedParams = ['ASWPARAM', 'aswparam', '_ga'];

				url.search = '';
				var btnHrefWithoutParams = url.toString().split('#')[0];

				for (var i = 0; i < unwantedParams.length; i++) {
					URLParams.delete(unwantedParams[i]);
				}
				var URLWithoutUnwantedParams = btnHrefWithoutParams + '?' + URLParams.toString() + hash;
				return URLWithoutUnwantedParams;
			},

			// Remove unwanted URL parameters from URL
			removeUnwantedParamsFromUrl: function (URLParams) {
				var unwantedParams = ['ASWPARAM', 'aswparam', '_ga'];
				for (var i = 0; i < unwantedParams.length; i++) {
					URLParams.delete(unwantedParams[i]);
				}
				var URLWithoutUnwantedParams = window.location.host + window.location.pathname + '?' + URLParams.toString();
				return URLWithoutUnwantedParams;
			}
		};

		// Custom event from 'otBannerSdk.js' script. It watches changes in cookie consent
		window.addEventListener('OneTrustGroupsUpdated', function () {
			// on change push cookie data to sdl datalayer
			var consentIdMatch = document.cookie.match(/OptanonConsent=.*?(consentId=(.*?)(?=&))/);
			var consentId = consentIdMatch ? consentIdMatch[2] : '';

			sdl.push({
				client: {
					cmpId: consentId,
				},
				consentGroups: window.OptanonActiveGroups ? window.OptanonActiveGroups : '',
				user: {
					update: {
						consent: {}
					}
				},
				event: "user.update.consent"
			});
		});

		sdlSecondaryObj.buttons();
		sdlSecondaryObj.modal();

	} catch (error) {
		console.error(error);
        // Push error information to sdl
		sdl.push({
			system: {
				error: {
					type: error.name,
					message: error.message,
                    description: 'singleDL-secondary.js'
				}
			},
			event: 'system.error'
		});
	}
})();
